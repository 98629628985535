.dashboardLayout {
      display: flex;
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      
      .content {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            background-color: #ffffff;
            color: #333333;
      }
}

.skeletonLoader {
      width: 100%;
      height: auto;
      min-height: 200px; /* Example height, adjust as needed */
      background-color: #f0f0f0;
      margin: 0;
      padding: 0;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Ensure responsiveness */
@media (max-width: 768px) {
      .skeletonLoader {
            min-height: 150px; /* Adjust for smaller screens */
      }
}
