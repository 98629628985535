.signInPage {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
}

.auth-container {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      width: 100%;
      max-width: 400px;
}

.auth-container h2 {
      margin-bottom: 1.5rem;
      text-align: center;
      color: #333;
}

.auth-error {
      background-color: #ffebee;
      color: #d32f2f;
      padding: 0.75rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      font-size: 0.875rem;
}

.auth-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
}

.form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
}

.form-row {
      display: flex;
      gap: 1rem;
}

.form-row .form-group {
      flex: 1;
}

.form-group label {
      font-size: 0.875rem;
      font-weight: 500;
      color: #555;
}

.form-group input {
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      transition: border-color 0.2s;
}

.form-group input:focus {
      border-color: #4a90e2;
      outline: none;
}

.auth-button {
      background-color: #4a90e2;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.75rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;
      margin-top: 0.5rem;
}

.auth-button:hover {
      background-color: #3a7bc8;
}

.auth-button:disabled {
      background-color: #a0c3e8;
      cursor: not-allowed;
}

.auth-links {
      margin-top: 1.5rem;
      text-align: center;
      font-size: 0.875rem;
}

.auth-links a {
      color: #4a90e2;
      text-decoration: none;
}

.auth-links a:hover {
      text-decoration: underline;
}