.institution-users-management {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 15px;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover {
  background-color: #f0f0f0;
}

.dashboard-actions {
  margin-bottom: 20px;
}

.add-user-btn {
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.add-user-btn:hover {
  background-color: #303f9f;
}

.add-user-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.submit-btn:hover {
  background-color: #388e3c;
}

.users-list {
  margin-top: 20px;
}

.users-list table {
  width: 100%;
  border-collapse: collapse;
}

.users-list th,
.users-list td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.users-list th {
  background-color: #f2f2f2;
  font-weight: 600;
}

.users-list tr:hover {
  background-color: #f5f5f5;
}

.deactivate-btn,
.activate-btn {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.deactivate-btn {
  background-color: #f44336;
  color: white;
}

.activate-btn {
  background-color: #4caf50;
  color: white;
}

.deactivate-btn:hover {
  background-color: #d32f2f;
}

.activate-btn:hover {
  background-color: #388e3c;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

.error {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
}