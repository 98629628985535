/* SuperAdmin Dashboard Styles */
:root {
  --primary-color: #1976d2;
  --primary-dark: #1565c0;
  --primary-light: #bbdefb;
  --secondary-color: #f5f5f5;
  --text-color: #333;
  --border-color: #e0e0e0;
  --success-color: #4caf50;
  --danger-color: #f44336;
  --warning-color: #ff9800;
  --info-color: #2196f3;
  --purple-color: #9c27b0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Segoe UI', Arial, sans-serif;
  background-color: #f9f9f9;
  color: var(--text-color);
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar Styles */
.sidebar {
  width: 220px;
  background-color: #1e3a5f;
  color: white;
  transition: width 0.3s;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  z-index: 10;
}

.sidebar-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.back-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.sidebar-menu {
  padding: 0;
  list-style: none;
  margin: 0;
}

.sidebar-menu li {
  padding: 0;
  margin: 0;
}

.sidebar-menu a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  transition: background-color 0.2s;
  border-left: 3px solid transparent;
}

.sidebar-menu a.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-left-color: var(--primary-color);
}

.sidebar-menu a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.sidebar-menu i {
  margin-right: 10px;
  font-size: 18px;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  margin-left: 220px;
  padding: 0;
  transition: margin-left 0.3s;
}

.main-header {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.content-area {
  padding: 20px;
}

/* Module Content Styles */
.module-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.module-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
}

.module-actions {
  display: flex;
  gap: 10px;
}

/* Button Styles */
.btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s, transform 0.1s;
}

.btn:hover {
  transform: translateY(-1px);
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-dark);
}

.btn-secondary {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.btn-secondary:hover {
  background-color: #e0e0e0;
}

.btn-success {
  background-color: var(--success-color);
  color: white;
}

.btn-success:hover {
  background-color: #43a047;
}

.btn-danger {
  background-color: var(--danger-color);
  color: white;
}

.btn-danger:hover {
  background-color: #e53935;
}

.btn-warning {
  background-color: var(--warning-color);
  color: white;
}

.btn-warning:hover {
  background-color: #fb8c00;
}

.btn-info {
  background-color: var(--info-color);
  color: white;
}

.btn-info:hover {
  background-color: #1e88e5;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  margin-top: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

thead th {
  background-color: #f5f5f5;
  font-weight: 500;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  color: #555;
}

tbody td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Status Badge Styles */
.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-badge.active {
  background-color: rgba(76, 175, 80, 0.1);
  color: var(--success-color);
}

.status-badge.inactive {
  background-color: rgba(244, 67, 54, 0.1);
  color: var(--danger-color);
}

.status-badge.pending {
  background-color: rgba(255, 152, 0, 0.1);
  color: var(--warning-color);
}

/* Action Button Styles */
.action-buttons {
  display: flex;
  gap: 5px;
}

.view-btn, .edit-btn, .users-btn, .deactivate-btn {
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-btn {
  background-color: var(--info-color);
  color: white;
}

.edit-btn {
  background-color: var(--warning-color);
  color: white;
}

.users-btn {
  background-color: var(--purple-color);
  color: white;
}

.deactivate-btn {
  background-color: var(--danger-color);
  color: white;
}

/* Search and Filter Styles */
.search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.search-bar {
  position: relative;
  flex: 1;
  max-width: 300px;
}

.search-bar input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.search-bar input:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Bulk Actions Styles */
.bulk-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bulk-actions select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: white;
  font-size: 14px;
}

.bulk-action-btn {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.bulk-action-btn:hover {
  background-color: var(--primary-dark);
}

.bulk-action-btn:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Checkbox Styles */
.select-all-checkbox {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.select-all-checkbox input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.select-all-checkbox span {
  font-size: 14px;
  color: #555;
}

table input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  background-color: white;
  border: 1px solid var(--border-color);
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination button:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.pagination button:disabled {
  color: #aaa;
  cursor: not-allowed;
}

.pagination .page-info {
  color: #555;
  font-size: 14px;
}

/* Form Styles */
.form-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-section {
  margin-bottom: 20px;
}

.form-section h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--primary-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

.form-group input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #777;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid var(--border-color);
  gap: 10px;
}

/* Institution View Modal */
.institution-view-modal {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.institution-details {
  padding: 20px;
}

.institution-details h3 {
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
}

.detail-row {
  margin-bottom: 10px;
  display: flex;
}

.detail-label {
  font-weight: 600;
  width: 150px;
  color: #555;
}

.detail-value {
  color: #333;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }
  
  .sidebar-header h2,
  .sidebar-menu span {
    display: none;
  }
  
  .main-content {
    margin-left: 60px;
  }
  
  .search-filter-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-bar {
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .bulk-actions {
    margin-top: 10px;
  }
  
  .module-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .module-actions {
    margin-top: 10px;
  }
}

/* Loading and Error States */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #777;
}

.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: var(--danger-color);
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}